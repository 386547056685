.login {
  background: #0073af;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 62.5%;
}

.content_login {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 90vh;
}

.loginTitleContainer {
  background: #0073af;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginTitleContainer .loginTitle {
  color: white;
  font-size: 3rem;
  margin-bottom: 0;
}

.loginLogo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loginButtonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.loginButtonContainer button {
  width: 20rem;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background: white;
  color: black;
  padding: 1rem;
  cursor: pointer;
  border-radius: 4px;
}

.loginButtonContainer button:hover,
.loginButtonContainer button:active,
.loginButtonContainer button:focus {
  background: rgb(0, 0, 0, 0.3);
  color: white;
}

.googleIcon{
  width: 20px;
}

.loginButtonContainer .googleIcon {
  margin-right: 5px;
  margin-bottom: 0;
}

.loginButtonContainer button::selection {
  background: #0073af;
  border: #0073af;
}

.msg_error {
  padding: 1.5rem 2.3rem;
  color: white;
  background: rgb(0, 0, 0, 0.1);
}

.error_area {
  position: absolute;
  top: 15vh;
  left: 37vw;
}

.footer_login {
  bottom: 0;
  width: 100%;
  height: 10vh;
  background: #0073af;
  display: flex;
  align-items: center;
}

.footer_login p {
  margin: 15px 15px;
  color: white;
}

@media only screen and (max-width: 1024px) {
  .loginTitleContainer .loginTitle {
    font-size: 1.7rem;
  }
}
