.divspanShow {
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  width: 10rem;
  cursor: pointer;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0.8rem 1.6rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0.2rem;
}

.selectorEnvlq {
  width: 100%;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: transparent;
  -webkit-appearance: none;
  border: 0;
  border-style: initial;
  border-color: initial;
  border-image: initial;
}
